<template>
  <div class="background" @click="closePopUp">
    <div class="card-container" style="cursor: default" v-on:click.stop>
      <div class="title">Восстановление пароля</div>
      <div class="divider"></div>
      <div class="card-content">


        <div class="btn-check-email">
          <div v-if="isPhone" class="check-on" @click="isPhone=false"/>
          <div v-if="!isPhone" class="check-off" @click="isPhone=false"/>
          <div class="radio-text" @click="isPhone=false">{{ "По E-mail" }}</div>
        </div>

        <div class="btn-check-email" style="margin-top: 16px">
          <div v-if="!isPhone" class="check-on" @click="isPhone=true"/>
          <div v-if="isPhone" class="check-off" @click="isPhone=true"/>
          <div class="radio-text" @click="isPhone=true">{{ "Нет E-mail" }}</div>
        </div>


        <div class="v-search-bar">
          <input class="edittext" type="text" placeholder="Номер договора" v-model="this.number"/>
        </div>
        <div class="btn" @click="getPassword()">Получить пароль</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pop-up-get-password",
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      number: '',
      isPhone: true
    }
  },
  methods: {
    closePopUp() {
      this.$emit('close')
    },
    getPassword() {
      this.$emit('getPassword', this.number, this.isPhone)
    }
  }
}
</script>

<style scoped>

.background {
  cursor: pointer;
  background: rgba(4, 26, 61, 0.4);
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.edittext {
  width: calc(100% - 32px);
  font-family: "Mont", serif;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #101010;
  padding-top: 8px;
  padding-bottom: 6px;
  margin: 16px 16px 16px 16px;
  text-align: center;
  border-width: 0;
  outline: none;
  height: 32px;
  background: #f3f3f3;
  border-radius: 12px;
}

.card-container {
  padding: 0px 16px 16px 16px;
  width: max-content;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow-y: hidden;
  height: max-content;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
  display: inline-block;
  grid-template-columns: 64px 1fr;
  grid-template-rows: 64px 1px 1fr;
  grid-template-areas:
      "back title"
      "divider divider"
      "content content";
}

.card-container .title {
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #2D666E;
  grid-area: title;
  align-self: center;
  margin-top: 12px;
  margin-bottom: 16px;
  justify-self: center;
  font-size: 1.5rem;
}


.card-container .divider {
  background: #e7e7e7;
  height: 1px;
  grid-area: divider;
  width: 100%;
}

.card-container .card-content {
  grid-area: content;
}

.btn {
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);

  cursor: pointer;
  border-radius: 10px;
  align-self: center;
  background: #58B1DA;
  color: white;
  font-family: "Mont", serif;
  padding: 16px 24px 12px 24px;
  margin: 16px 16px 16px 16px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.btn-check-email {
  justify-self: start;
  height: 40px;
  display: grid;
  grid-template-columns: 40px 1fr;
}

.radio-text {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  align-self: center;
  margin-left: 16px;
  width: max-content;
  color: #343434;
  justify-self: start;
  font-size: 1.2rem;
}

.check-on {
  cursor: pointer;
  justify-self: end;
  align-self: start;
  margin-top: 12px;
  background: #58B1DA;
  border-radius: 8px;
  height: 16px;
  width: 16px;
}

.check-off {
  cursor: pointer;
  margin-top: 12px;
  justify-self: end;
  align-self: start;
  background: #e3e3e3;
  border-radius: 8px;
  height: 16px;
  width: 16px;
}


</style>
